import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w09KG10Schnitzen = () => (
  <Layout>
    <SEO title="w09KG10Schnitzen" />

    <h1 id="ueberschrift">Kreatives Gestalten</h1>
    <h2>Schnitzen leicht gemacht &emsp;  ©&nbsp;1998</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w09KG10Schnitzen&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Figuren, Reliefs, Schmuck, etc.;<br />
        aus Holz, Speckstein, Gips, etc.;<br />
        für den Eigengebrauch, als Mitbringsel, als besonderes Geschenk, etc.
      </p>
      <p>
        Gemeinsam mit anderen können Sie Ihr kreatives Talent suchen und entdecken, indem Sie 
        unter Anleitung verschiedene Dinge aus bestimmten Grundmaterialien schnitzen, stemmen und feilen.
        Genießen Sie die Zeit beim geselligen Experimentieren, Fluchen, Beneiden und Freuen.
      </p>

      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Mitmachkurs
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Sitz- oder Stehplatz pro Teilnehmer + 1 Tisch und Sitzplatz extra<br/>
        Materialbedarf: + Schutzbrille +
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 2 Sitzplätze und 1 Tisch
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w09KG10Schnitzen
